
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  IssuePriority,
  PriorityTypes,
} from "@/store/modules/priority/priority.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const priorityX = namespace("Priority");

@Component({
  components: { DatePicker },
})
export default class EditPriority extends Mixins(ValidationMixin) {
  @priorityX.State(PriorityTypes.UPDATE_PRIORITY_STATE)
  public updatePriority!: DefaultState;

  @priorityX.State(PriorityTypes.PRIORITY_DATA)
  public issuePriority!: IssuePriority;

  @priorityX.Mutation(PriorityTypes.SET_UPDATE_PRIORITY_DIALOG)
  public setUpdatePriority!: (updatePriority: boolean) => void;

  @priorityX.Action(PriorityTypes.UPDATE_PRIORITY)
  public savePriority!: (farmer: IssuePriority) => Promise<void>;

  public updatePriorityStep = 1;

  public isValid = false;

  public priority: IssuePriority = {
    id: -1,
    name: "",
    description: "",
    precedence: 0,
    active: false,
    createdAt: new Date(),
    zones: [],
  };

  @Ref("form") public formRef!: Validator;

  closeUpdatePriorityDialog(): void {
    this.setUpdatePriority(false);
  }

  async savePriorityToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.savePriority(this.priority);
    }
  }

  @Watch("issuePriority")
  onDataChanged(value?: IssuePriority): void {
    this.priority = Object.assign({}, value);
  }

  mounted(): void {
    this.priority = Object.assign({}, this.issuePriority);
  }
}
